<template>
  <div class="newPathAni-wrap">
    <div v-for="(v,i) in len" :key="'ani' + i" :style="{
      width:  15 + 'px',
      height: 3 + 'px',
      position: 'absolute',
      top: top || 0 + 'px',
      left: left || 0 + 'px',
      zIndex: 999,
      boxShadow: show || '0 0 10px #c977a3',
      backgroundColor: `rgba(255,255,255,${((len || 20) - i) / (len || 20)})`,
      animation: `move ${during || 10}s ${0.03 * i}s infinite`,
      offsetPath: offsetPath
      }">
    </div>
  </div>

</template>

<script>

export default {
  name: 'newPathAni',
  data(){
    return {
      offsetPath: 'path("' + this.path + '")',
    }

  },
  // props: {
  //   path: [String, Number],
  //   artopeaName: [String, Number],
  //   cityName: [String, Number],
  //   flag:[String, Number],
  //   regionId: [String, Number],
  //   regionType: [String, Number],
  // },
  props:{
    path: {
      type: String,
      default: ''
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    len: {
      type: Number,
      default: 0
    },
    show: {
      type: String,
      default: ''
    },
    he: {
      type: Number,
      default: 0
    },
    during: {
      type: Number,
      default: 0
    },
  },
  // props:[path,top,left,len,show,show,he,during],
  components: {
  },
  created(){
    console.log(this.top)
    // this.offsetPath = 'path("' + this.path + '")';
  },
  methods:{
  }
}
</script>
<style scoped>
@keyframes move {
  0%{
    offset-distance: 0%;
    opacity: 0;
  }
  5%{
    opacity: 0;
  }
  50%{
    offset-distance: 50%;
    opacity: 1;
  }
  80%{
    opacity: 0;
  }
  100% {
    offset-distance: 100%;
    opacity: 0;
  }
}
</style>
