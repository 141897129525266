<template>
<!--  <div :class="`login-wrap`">-->
  <div :class="`login-wrap ${this.$route.query.referer ? 'white-bg' : ''}`">
    <div class="meteorwhiteData_box">
      <div v-for="(v,i) in whitemeter" class="meteorwhiteData_child" :key="'meteorwhiteData_box1' + i" :style="v"></div>
      <div v-for="(v,i) in bluemeter" class="meteorblueData_child" :key="'meteorblueData_box' + i" :style="v" ></div>

    </div>
  <div v-for="(v,i) in upData" :class="`login_meteor ${'login_meteor' + v}`" :key="'login_meteor' + i"></div>
  <div class="earth_radius"></div>
  <div class="music"></div>
  <div class="four_line"></div>
  <div class="login_earth">
    <div class="login_city">{{this.city}}</div>
    <div class="login_title">{{this.title}}</div>
    <div class="login_box">
<!--      <input  type="hidden"/>-->
      <input class="login_username" type="text" placeholder="请输入用户名" autocomplete="new-password" v-model="name" />
      <input class="login_username" :type="ispass" placeholder="请输入密码" autocomplete="new-password" v-model="pass" />
      <div class="login_checkbox">
        <input type="checkbox" name="" v-model="rememberPassword" @change="rememberPass"/>  记住密码
      </div>

      <div :class="iseye ? 'biyan' : 'zhengyan'  " @click="iseye = !iseye;ispass = iseye ? 'password': 'text'"></div>
      <div class="login_code">{{this.code}}</div>
      <div class="click_login" @click="onFinish">登录</div>
    </div>
  </div>
    <!--  杭州版本  -->
<!--  <div class="login_bottom"></div>-->

  </div>
</template>

<script>
import {login} from "@/api/common";
import PathAni from '../components/pathAni/newPathAni.vue';

export default {
  name: "Login",
  data(){
    return {
      city: '',
      title: '预付卡综合监管实时大数据报告',
      name: '',
      pass: '',
      ispass: 'password',
      iseye: true,
      code: '',
      whitemeter: [
        { width: 5, height: 5, top: 111, left: 266 },
        { width: 4, height: 4, top: 275, left: 246 },
        { width: 4, height: 4, top: 445, left: 290 },
        { width: 4, height: 4, bottom: 234, left: 340 },
        { width: 5, height: 5, top: 316, right: 255 },
        { width: 5, height: 5, top: 593, right: 290 },
        { width: 5, height: 5, bottom: 321, right: 415 }
      ],
      bluemeter: [
        { width: 3, height: 3, top: 151, left: 266 },
        { width: 2, height: 2, top: 275, left: 146 },
        { width: 3, height: 3, top: 355, left: 200 },
        { width: 3, height: 3, bottom: 134, left: 300 },
        { width: 2, height: 2, top: 216, right: 205 },
        { width: 2, height: 2, top: 503, right: 290 },
        { width: 2, height: 2, bottom: 321, right: 315 }
      ],
      upData: [1, 2, 3, 4, 5, 6, 7, 8],
      path1: "M 574, 0 C 861,0 1148,60 1148,184 C 1148 285, 861 375, 574 367 C 287 367, 1 285, 1 184 C 1 50, 287 0, 574, 0  Z",
      path2: "M 574, 0 C 861,0 1148,60 1148,184 C 1148 285, 861 375, 574 370 C 287 370, 1 285, 1 184 C 1 60, 287 0, 574, 0 Z",
      rememberPassword: localStorage.getItem("rememberPassword") == 'true' ?  true : false,
    }
  },
  components:{
    PathAni
  },
  created() {
    if(localStorage.getItem("rememberPassword") == 'true'){
      console.log(111)
      this.name = localStorage.getItem("kangcunName");
      this.pass = localStorage.getItem("kangcunPass");
    }else{
      console.log(222)
      this.name = '';
      this.pass = '';
    }
    this.whitemeter.forEach((v,i)=>{
      for(let ind in v){
        v[ind] = v[ind] + 'px'
      }
    })
    this.bluemeter.forEach((v,i)=>{
      for(let ind in v){
        v[ind] = v[ind] + 'px'
      }
    })


    if(this.$route.query.referer){
      function parseSearchArgs() {
        var url = window.location.search; //获取url中"?"符后的字串
        var rst = {};
        if (url.indexOf("?") != -1) {
          var str = url.split("?")[1];
          var parts = str.split("&");
          for (var i = 0; i < parts.length; i++) {
            rst[parts[i].split("=")[0]] = decodeURI(parts[i].split("=")[1]);
          }
        }
        return rst;
      }
      let params = parseSearchArgs()
      this.name = params.kangcunName;
      this.pass = window.atob(unescape(params.kangcunPass));
      // this.name = this.getCookie("kangcunName");
      // this.pass = this.getCookie("kangcunPass");
      return this.onFinish()
    }


    console.log(this.$route,'login=======')
    if(this.$route.params.noPass){
      this.$message.error("无访问权限，请登录")
    }
  },
  methods:{
    getCookie(key){
      var arr1=document.cookie.split("; ");//由于cookie是通过一个分号+空格的形式串联起来的，所以这里需要先按分号空格截断,变成[name=Jack,pwd=123456,age=22]数组类型；
      for(var i=0;i<arr1.length;i++){
        var arr2=arr1[i].split("=");//通过=截断，把name=Jack截断成[name,Jack]数组；
        if(arr2[0]==key){
          return decodeURI(arr2[1]);
        }
      }
    },
    //记住密码
    rememberPass(){
      console.log(this.rememberPassword)
      localStorage.setItem("rememberPassword",this.rememberPassword)
    },
    onFinish() {
      login({
        loginName: this.name,
        password: this.pass,
        isFormData:true,
      }).then((res)=>{

        if(res.code === 1){
          this.$message.error('用户名或密码错误')
        }else{
          console.log(res.data[2])
          localStorage.setItem("bigDataMenus",JSON.stringify(res.data[2]))
          localStorage.setItem("permissions", new Date().toLocaleDateString())
          localStorage.setItem("cityRegionId", res.data.regionId)

          localStorage.setItem("kangcunName", this.name)
          localStorage.setItem("kangcunPass", this.pass)

          if(res.data[2].length){
            this.$router.push({
              name:res.data[2][0].url.replace('/',''),
            })
          }else{
            this.$message.error("无菜单权限，请通过管理员添加")
          }

          // this.$router.push("/")
        }

      }).catch((err)=>{
        this.$message.error(err)
      })

    },
  }
}
</script>

<style scoped lang="scss">
.login-wrap{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../assets/imgs/login/back.jpg") no-repeat;
  background-size: 100% 100%;
  &.white-bg::after{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999
  }
  .login_earth{
    width: 850px;
    height: 852px;
    //    background: url(./image/earth.png") no-repeat;
    //    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -426px 0 0 -425px;
    color: white;
    font-size: 36px;
    font-family: MicrosoftYaHeiUILight;
    .login_city{
      margin-top:150px;
      width: 100%;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }
    .login_title{
      width: 100%;
      height: 60px;
      text-align: center;
      font-size: 46px;
      line-height: 40px;
    }
    .login_box{
      //    background: rgba(255, 255, 255, 0.2);
      z-index: 9;
      width: 400px;
      height:293px;
      position: absolute;
      top: 300px;
      left: 225px;
      .login_username{
        width: 100%;
        height: 56px;
        border: 2px solid white;
        border-radius: 5px;
        font-size: 18px;
        color: white;
        background: rgba(0,0,0,0);
        text-indent: 18px;
        margin-bottom:28px;
        position: relative;
      }
      .login_checkbox{
        position: absolute;
        left: 20px;
        top: 190px;
        font-size: 14px;
        &>input{
          cursor: pointer;
          width: 18px;
          height: 18px;
          vertical-align: text-top;
        }
      }


      .login_username::input-placeholder {
        color: #97a0bf;
      }
      .login_code{
        position: absolute;
        font-size: 18px;
        color: red;
        top:170px;
        left:10px;
      }
      .click_login{
        width: 200px;
        height: 60px;
        font-size: 24px;
        color: white;
        line-height: 60px;
        text-align: center;
        background: -moz-linear-gradient(right, #00edf0 0%, #2163ff 100%);
        background: -o-linear-gradient(right, #00edf0 0%,#2163ff 100%);
        background: -ms-linear-gradient(right, #00edf0 0%,#2163ff 100%);
        background: -webkit-linear-gradient(left, #00edf0 0%,#2163ff 100%);
        border-radius: 30px;
        margin-left: 95px;
        margin-top: 40px;
        cursor: pointer;
      }
      .biyan{
        position: absolute;
        width:26px;
        height: 15px;
        right:10px;
        top:117px;
        background: url("../assets/imgs/login/zy.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .zhengyan{
        position: absolute;
        width:26px;
        height: 15px;
        right:10px;
        top:117px;
        background: url("../assets/imgs/login/by.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
  .login_bottom{
    width: 100%;
    height: 61px;
    position: absolute;
    bottom: 20px;
    left: 0px;
    background: url("../assets/imgs/login/login-bottom.png") no-repeat center;
  }

  .four_line{
    width: 1018px;
    height: 1018px;
    background: url("../assets/imgs/login/fourline.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -509px 0 0 -509px;
    animation: zhuan 45s linear infinite;
  }
  .left_line{
    width: 854px;
    height: 856px;
    background: url("../assets/imgs/login/leftline.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 76px;
    left: 408px;
    .left_line_rotate1{
      width: 1148px;
      height: 370px;
      transform: rotate(45deg);
      position: absolute;
      top: 249px;
      left: -153px;
    }
  }
  .right_line{
    width: 854px;
    height: 856px;
    background: url("../assets/imgs/login/rightline.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 76px;
    right: 408px;
    .right_line_rotate1{
      width: 1148px;
      height: 370px;
      transform: rotate(-45deg);
      position: absolute;
      top: 249px;
      left: -143px;
    }
  }
  .music{
    width: 1056px;
    height: 1056px;
    background: url("../assets/imgs/login/music.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -528px 0 0 -533px;
  }
  .earth_radius{
    width: 850px;
    height: 852px;
    background: rgba(0,18,91,1);
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -426px 0 0 -425px;
    border-radius: 50%;
  }
  .meteorwhiteData_box{
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left: 0;
    .meteorwhiteData_child{
      position: absolute;
      width:5px;
      height:5px;
      border:1px solid #1d3fb8;
      background: #fff;
      border-radius: 50%;
      animation: opc 3s linear infinite;
      box-shadow: 0 0 5px #1d3fb8;
    }
    .meteorblueData_child{
      position: absolute;
      width:5px;
      height:5px;
      background: rgba(0,246,251,0.5);
      border-radius: 50%;
      animation: opc 3s linear infinite;
      box-shadow: 0 0 5px #00f6fb;
    }
  }
  .login_meteor{
    width: 83px;
    height: 61px;
    background: url("../assets/imgs/login/meteorthree.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
  }
  .login_meteor1{
    animation: meteor1 8s linear infinite;
  }
  .login_meteor2{
    animation: meteor2 5s linear infinite;
    opacity: 0;
    animation-delay:2s;
  }
  .login_meteor3{
    animation: meteor3 8s linear infinite;
  }
  .login_meteor4{
    animation: meteor4 5s linear infinite;
  }
  .login_meteor5{
    animation: meteor5 8s linear infinite;
    opacity: 0;
    animation-delay:2s;
  }
  .login_meteor6{
    animation: meteor6 5s linear infinite;
  }
  .login_meteor7{
    animation: meteor7 5s linear infinite;
    opacity: 0;
    animation-delay:2s;
  }
  .login_meteor8{
    animation: meteor8 5s linear infinite;
  }
}

@keyframes zhuan {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes opc {
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@keyframes meteor1{
  0%{
    opacity: 1;
    top: 114px;
    right: 338px;
  }
  100%{
    opacity: 0;
    top: 786px;
    right: 1597px;
  }
}
@keyframes meteor2{
  0%{
    opacity: 1;
    top: 100px;
    right: 643px;
  }
  100%{
    opacity: 0;
    top: 715px;
    right: 1811px;
  }
}
@keyframes meteor3{
  0%{
    opacity: 1;
    top: 252px;
    right: 210px;
  }
  100%{
    opacity: 0;
    top: 948px;
    right: 1054px;
  }
}
@keyframes meteor4{
  0%{
    opacity: 1;
    top: 484px;
    right: 204px;
  }
  100%{
    opacity: 0;
    top: 951px;
    right: 702px;
  }
}
@keyframes meteor5{
  0%{
    opacity: 1;
    top: 175px;
    right: 240px;
  }
  100%{
    opacity: 0;
    top: 862px;
    right: 1237px;
  }
}
@keyframes meteor6{
  0%{
    opacity: 1;
    top: 252px;
    right: 344px;
  }
  100%{
    opacity: 0;
    top: 946px;
    right: 1282px;
  }
}
@keyframes meteor7{
  0%{
    opacity: 1;
    top: 123px;
    right: 507px;
  }
  100%{
    opacity: 0;
    top: 802px;
    right: 1774px;
  }
}
@keyframes meteor8{
  0%{
    opacity: 1;
    top: 0;
    left: 497px;
  }
  100%{
    opacity: 0;
    top: 302px;
    left: 0;
  }
}
@keyframes float{
  0%{
    opacity: 0.6;
    top: 200px;
    transform:scale(0.5) rotateX(70deg);
  }
  90%{
    opacity: 1;
    top: 400px;
    transform:scale(1) rotateX(70deg);
  }
  100%{
    opacity: 0;
    top: 400px;
    transform:scale(1) rotateX(70deg);
  }
}


</style>
