var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newPathAni-wrap"},_vm._l((_vm.len),function(v,i){return _c('div',{key:'ani' + i,style:({
    width:  15 + 'px',
    height: 3 + 'px',
    position: 'absolute',
    top: _vm.top || 0 + 'px',
    left: _vm.left || 0 + 'px',
    zIndex: 999,
    boxShadow: _vm.show || '0 0 10px #c977a3',
    backgroundColor: ("rgba(255,255,255," + (((_vm.len || 20) - i) / (_vm.len || 20)) + ")"),
    animation: ("move " + (_vm.during || 10) + "s " + (0.03 * i) + "s infinite"),
    offsetPath: _vm.offsetPath
    })})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }